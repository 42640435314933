import {BrowserRouter as Router} from 'react-router-dom';
import {ScrollToTop} from 'components';

import {routes as DefaultRoutes} from './Default';
import {routes as AuthRoutes} from './Auth';
import {routes as EventRoutes} from './Event';
import {routes as CategoryRoutes} from './Category';
import {routes as DemoRoutes} from './Demo';
import {routes as ApplicationsRoutes} from './Applications';
import {routes as UserRoutes} from './Users';

export const ROUTES = {
  home: '/',
  signin: '/auth/signin',
  signout: '/auth/signout',
  categories: '/categories',
  page404: '/404',
  event: '/event',
  events: '/events',
  auth: '/auth',
  me: '/',
  applications: '/applications',
  users: '/users',
};

export const InternalRoutes = () => {
  return (
    <Router>
        <ScrollToTop>
          <AuthRoutes/>
          <DefaultRoutes/>
          <UserRoutes/>
          <EventRoutes/>
          <CategoryRoutes/>
          <ApplicationsRoutes/>
          <DemoRoutes/>
        </ScrollToTop>
    </Router>
  );
};



