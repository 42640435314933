import {useState, useEffect, useReducer} from 'react';
import {Button, Icon, Modal, Form} from 'semantic-ui-react';
import {
    serverTimestamp,
    setDoc,
} from 'firebase/firestore';
import {useFirebaseContext} from 'hooks';
import {GenericObject} from '@kwixl/elements';
import {flash, getDocId, reducer} from 'lib';
import styled from 'styled-components';

interface Props {
    open?: boolean;
    value?: GenericObject;
    parents: GenericObject[];
    onClose: () => void;
}
export const EditCategoryModal = ({ open = false, value = {}, parents = [], onClose = () => {} }: Props) => {

    console.log('VALUE', value);
    
    const [active, setActive] = useState(false);

    const [{ 
        name = '',
        parentId = '',
        slug = '',
    }, dispatch] = useReducer(reducer, value);

    const {categoryRef} = useFirebaseContext();

    useEffect(() => {
        setActive(open);
    },[open]);

    const handleChange = (e, {name, value }) => dispatch({ [name]: value });

    const handleClose = () => {
        setActive(false);
        dispatch({ name: '', parentId: '', slug: '' });
        onClose();
    }

    const save = async () => {
        try {
            const id = value?.id || getDocId();
            await setDoc(categoryRef(id), {
                name,
                parentId,
                slug,
                rootCategory: parentId || id,
                updatedAt: serverTimestamp(),
            }, {
                merge: true
            });
            flash.success('Category saved.');
            handleClose();
        } catch (err: any) {
            flash.error(err.message);
        }
    }

    console.log('PARENT', parentId);

    return (
        <Modal
            size="small"
            open={active}
            onOpen={() => setActive(true)}
            onClose={handleClose}
        >
            <Modal.Header>
                {value?.id ? 'Edit' : 'Add'} Category
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <Form.Dropdown 
                            placeholder="----"
                            name="parentId"
                            selection
                            label={'Parent'}
                            options={parents}
                            value={parentId}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            name="name"
                            label="Name"
                            value={name}
                            onChange={handleChange}
                            fluid
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            name="slug"
                            label="Slug"
                            value={slug}
                            onChange={handleChange}
                            fluid
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    icon
                    color='red'
                    onClick={() => handleClose()}
                >
                    <Icon name="close"/> Cancel
                </Button>
                <Button
                    icon
                    color='green'
                    onClick={() => save()}
                >
                    <Icon name="check"/> Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const NameLink = styled.a`
    cursor: pointer;
    & hover: {
        text-decoration: underline;
    }
`