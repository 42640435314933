import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {AdminRoute} from 'components';
import {Main} from './Main';

export const routes = () => {

  console.log('IN ROUTES');

  return (
    <Routes>
      <Route path="/" element={<AdminRoute/>}>
          <Route path="/" element={<Main/>}/>
      </Route>
    </Routes>
  )

};

export default routes;
