import {useEffect, useState, useRef} from 'react';
import {List, Button, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {withAuthenticatedPage} from 'hoc';
import {useFirebaseContext} from 'hooks';
import {
    doc,
    onSnapshot,
} from 'firebase/firestore';
import {Loading, GenericObject} from '@kwixl/elements';
import {EditCategoryModal} from './components';

export const Main = withAuthenticatedPage(({ returnHeader }) => {

    const {db} = useFirebaseContext();

    const [cats, setCats] = useState<GenericObject>();
    const [parents, setParents] = useState<GenericObject[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [category, setCategory] = useState<GenericObject>();

    const listener = useRef(() => {});

    useEffect(() => {
        return () => listener.current();
    },[]);

    useEffect(() => {
        setParents(Object.keys(cats || {}).map(key => (
            {
                key,
                value: key,
                text: cats[key].name
            }
        )));
    },[cats]);

    useEffect(() => {
        (async () => {
            if (!db) return;
            listener.current = onSnapshot(doc(db, 'categories', 'root'), result => setCats(result.data()));
        })()
    },[db]);

    const openModal = (c: GenericObject) => {
        setCategory(c);
        setModalOpen(true);
    }

    if (!cats) return <Loading/>;

    return (
        <>
            {returnHeader('Categories')}
            <Button color="blue" icon onClick={() => openModal({})}><Icon name="plus"/></Button>
            {Object.keys(cats)
                .sort((a: string, b: string) => cats[a].name.localeCompare(cats[b].name))
                .map(id => {
                    const category = cats[id];
                    return (
                        <List>
                            <List.Item>
                                <List.Header>
                                    <Link to={null} key={id} onClick={() => openModal({ ...category, id })}>{category.name}</Link>
                                </List.Header>
                            </List.Item>
                            <List.Item>
                                <List>
                                    {Object.keys(category.children || {})
                                        .sort((a: string, b: string) => category.children[a].name.localeCompare(category.children[b].name))
                                        .map(key => {
                                        const child = category.children[key];
                                        return (
                                            <List.Item>
                                                <Link to={null} key={key} onClick={() => openModal({ ...child, parentId: id, id: key })}>{child.name}</Link>
                                            </List.Item>
                                        )
                                    })}
                                </List>
                            </List.Item>
                        </List>
                    )
                })
            }
            { modalOpen && 
                <EditCategoryModal 
                    open={modalOpen}
                    value={category} 
                    parents={parents} 
                    onClose={() => { 
                        setCategory(null);
                        setModalOpen(false);
                    }}
                />
            }
        </>
    )
});