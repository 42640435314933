import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Icon, Header} from 'semantic-ui-react';
import {useSessionContext, useFirebaseContext} from 'hooks';
import {withBasePage} from 'hoc';
import styled from 'styled-components';
import {DesktopLayout, MobileLayout} from 'layouts';
import {ROUTES} from 'routes';

export const withAuthenticatedPage = WrappedComponent => withBasePage(props => {
  console.log('Inside base page')
    const {mobile} = useSessionContext();
    const {userHome} = useFirebaseContext();
    const navigate = useNavigate();
    const returnHeader = (title: any, path?: string) => (
      <Header as="h2">
        <Icon
          name="chevron left"
          color="blue"
          link
          onClick={() => navigate(path || ROUTES.me)}
          style={{
            display: 'inline',
            marginRight: '8px',
            verticalAlign: 'bottom',
          }}
        />
        {title}
      </Header>
    );
    console.log('MOBILE', mobile);
    return mobile ? (
      <MobileLayout>
        <PageWrapper>
          <WrappedComponent {...props} returnHeader={returnHeader} />
        </PageWrapper>
      </MobileLayout>
    ) : (
      <DesktopLayout>
        <Content id="main-content">
          <WrappedComponent {...props} returnHeader={returnHeader} />
        </Content>
      </DesktopLayout>
    );
  });

const PageWrapper = styled.div`
  width: 95%;
  margin: 20px auto 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 16px;
`;

const Content = styled.div`
  padding: 40px 0;
  height: 100%;
  width: 95%;
  margin: 0 auto;
`;
