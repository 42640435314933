import {useReducer} from 'react';
import {Link} from 'react-router-dom';
import {Form, Button, Icon, Message} from 'semantic-ui-react';
import {RequiredField} from '@kwixl/elements';
import {useFirebaseContext} from 'hooks';
import {reducer, validate} from 'lib';
import {login_schema} from 'schema';

import {signInWithEmailAndPassword} from 'firebase/auth';

const initialState = {
  password: '',
  email: '',
  errors: {},
  loading: false,
};

export const EmailLogin = ({onSuccess = (result: any) => {}}) => {
  const {auth} = useFirebaseContext();

  const [{email, password, errors, loading}, dispatch] = useReducer(
    reducer,
    initialState
  );

  const handleLoginResult = async result => {
    if (onSuccess && typeof onSuccess === 'function') onSuccess(null);
  };

  const login = async () => {
    dispatch({errors: {}});

    const formErrors = validate({email, password}, login_schema);

    if (formErrors) {
      let e = {};
      Object.keys(formErrors).forEach(key => {
        let name = '';
        switch (key) {
          case 'email':
            name = 'E-mail';
            break;
          case 'password':
            name = 'Password';
            break;
          default:
            break;
        }
        e[key] = formErrors[key].replace(key, name).replace(/"/g, '');
      });
      dispatch({errors: e});
      return false;
    }

    dispatch({loading: true});
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      handleLoginResult(result);
    } catch (err) {
      console.log('Login Error:', err.message);
      dispatch({errors: {signin: 'Login error'}});
    }
    dispatch({loading: false});
  };

  const handleChange = (field, value) => {
    delete errors[field];
    dispatch({[field]: value, errors});
  };

  return (
    <>
      {Object.keys(errors || {}).length > 0 && (
        <Message negative>
          <Message.Header>Login Error</Message.Header>
          <p>
            There was an error signing in. Please check your login information
            and try again.
          </p>
        </Message>
      )}
      <Form noValidate error={Object.keys(errors || {}).length > 0}>
        <RequiredField error={!!errors.email} noMessage={true}>
          <Form.Input
            type="text"
            iconPosition="left"
            icon="at"
            value={email}
            placeholder={'Email Address'}
            onChange={(e, {value}) => handleChange('email', value)}
          />
        </RequiredField>
        <RequiredField error={!!errors.password} noMessage={true}>
          <Form.Input
            iconPosition="left"
            icon="lock"
            type="password"
            value={password}
            placeholder={'Password'}
            onChange={(e, {value}) => handleChange('password', value)}
          />
        </RequiredField>
        <Button
          icon
          fluid
          color="blue"
          onClick={login}
          loading={loading}
          disabled={loading}
        >
          <Icon name="sign in" /> Sign-In
        </Button>
        <span
          style={{
            fontSize: '11px',
            textAlign: 'right',
            display: 'block',
            width: '100%',
          }}
        >
          <Link to={'#'} onClick={() => window.location.replace('https://kwixl.com/auth/loginlink')}>Forgot password?</Link>
        </span>
      </Form>
    </>
  );
};
