import React, {useReducer, useEffect} from 'react';
import {reducer} from 'lib';
import throttle from 'lodash/throttle';
import {checkMobile} from '@kwixl/elements';
import {loadStripe} from '@stripe/stripe-js';

export interface ISessionContext {
  live: boolean;
  ready: boolean;
  orientation: string;
  mobile: boolean;
  demo: boolean;
  locale: string;
  setup_payment_method: boolean;
  stripePromise: any;
  updateSession: (data: {[key: string]: any}) => void;
}

const defaultState = {
  live: process.env.REACT_APP_STAGE === 'production' ? true : false,
  ready: true,
  orientation: 'landscape',
  mobile: false,
  demo: Boolean(process.env.REACT_APP_DEMO) === true,
  locale: 'en',
  setup_payment_method: true,
  stripePromise: null,
};

export const SessionContext =
  React.createContext<Partial<ISessionContext>>(defaultState);

export const SessionProvider = ({children}) => {
  const [state, setState] = useReducer(reducer, defaultState);

  useEffect(() => {
    window.addEventListener('resize', resize);
    window.addEventListener('orientationchange', orientationChange);
    setState({
      stripePromise: loadStripe(process.env.REACT_APP_STRIPE_KEY),
      mobile: checkMobile(),
      orientation:
        window.innerWidth < window.innerHeight ? 'portrait' : 'landscape',
    });
    return () => {
      window.removeEventListener('orientationchange', orientationChange);
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = throttle(e => {
    if (checkMobile() !== state.mobile) {
      setState({mobile: !state.mobile});
    }
  }, 250);

  const updateSession = data => {
    setState(data);
  };

  const orientationChange = throttle(e => {
    try {
      const {
        target: {
          screen: {
            orientation: {angle, type},
          },
        },
      } = e;
      switch (angle) {
        case 90:
        case 270:
          setState({orientation: 'landscape'});
          break;
        default:
          setState({orientation: 'portrait'});
          break;
      }
    } catch (err) {
      console.error(err);
    }
  }, 250);

  return (
    <SessionContext.Provider value={{...state, updateSession}}>
      {children}
    </SessionContext.Provider>
  );
};
