import {Routes, Route} from 'react-router-dom';

import {SignIn} from './SignIn';
import {SignOut} from './SignOut';

import {ROUTES} from 'routes';

export const routes = () => (
  <Routes>
    <Route path={ROUTES.auth}>
      <Route path={ROUTES.signin} element={<SignIn />} />
      <Route path={ROUTES.signout} element={<SignOut />} />
    </Route>
  </Routes>
);

export default routes;
