import React, {useState, Fragment} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Menu, Segment, Sidebar, Icon, Image, Button} from 'semantic-ui-react';
import styled from 'styled-components';
import {PageFooter, AuthenticatedMenu} from 'components';
import {useFirebaseContext} from 'hooks';
import {ROUTES} from 'routes';
import {Avatar} from '@kwixl/elements';

export const MobileLayout = ({
  inverted = true,
  children = null,
  fullScreen = false,
  footer = true,
  menu = true,
}) => {
  const {firebaseUser, userProfile} = useFirebaseContext();

  const messageCount = 0;
  const notificationCount = 0;

  const navigate = useNavigate();

  const [sidebarOpened, toggleSidebarOpened] = useState(false);

  const handleSidebarHide = () => toggleSidebarOpened(false);

  const handleToggle = () => toggleSidebarOpened(true);

  const layout = () => {
    return (
      <Fragment>
        <LayoutContainer id="mobile-layout-container">
          <LayoutWrapper id="mobile-layout-wrapper" fullScreen>
            {children}
          </LayoutWrapper>
        </LayoutContainer>
        {(!fullScreen || footer) && <PageFooter />}
      </Fragment>
    );
  };

  return fullScreen && !menu ? (
    layout()
  ) : (
    <Sidebar.Pushable>
      <Sidebar
        basic
        as={Menu}
        animation="overlay"
        direction="right"
        onHide={handleSidebarHide}
        vertical
        visible={sidebarOpened}
        inverted
      >
        <Menu.Item header>{process.env.REACT_APP_NAME}</Menu.Item>

        {firebaseUser && <AuthenticatedMenu />}

      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened}>
        <MobilePageContainer id="mobile-page-container">
          <Segment
            basic
            textAlign="center"
            style={{padding: '0', width: '100%'}}
            vertical
          >
            <div style={{backgroundColor: '#2185D0'}}>
              <Menu
                id="mobile-top-menu"
                pointing
                secondary
                style={{backgroundColor: 'transparent'}}
              >
                <Menu.Item>
                  <span
                    style={{
                      fontWeight: 'bolder',
                      fontSize: '1.25rem',
                      color: '#ffffff',
                    }}
                  >
                    <Link to="/" style={{color: '#ffffff'}}>
                      <Image
                        src="/android-icon-192x192.png"
                        size="mini"
                        style={{display: 'inline-block'}}
                      />{' '}
                      {process.env.REACT_APP_NAME} Live Commerce
                    </Link>
                  </span>
                </Menu.Item>
                <Menu.Item position="right">
                  {firebaseUser ? (
                    <Menu.Menu>
                      <Menu.Item style={{padding: 0}}>
                        <span style={{marginRight: '1rem'}}>
                          <Icon
                            link
                            onClick={() => navigate('/my/messages')}
                            name="mail outline"
                            style={{
                              color: `${inverted ? '#ffffff' : '#000000'}`,
                              fontSize: '1.25rem',
                              marginTop: '.6rem',
                            }}
                          />
                          {messageCount > 0 && (
                            <span
                              style={{
                                color: `${inverted ? '#ffffff' : '#000000'}`,
                              }}
                            >
                              {messageCount}
                            </span>
                          )}
                        </span>
                        <span style={{marginRight: '1rem'}}>
                          <Icon
                            link
                            onClick={() => navigate('/my/notifications')}
                            name="bell outline"
                            style={{
                              color: `${inverted ? '#ffffff' : '#000000'}`,
                              fontSize: '1.25rem',
                              marginTop: '.6rem',
                            }}
                          />
                          {notificationCount > 0 && (
                            <span
                              style={{
                                color: `${inverted ? '#ffffff' : '#000000'}`,
                              }}
                            >
                              {notificationCount}
                            </span>
                          )}
                        </span>
                        <Link
                          to="/my/profile"
                          style={{
                            marginRight: '15px',
                            height: '2rem',
                            width: '2rem',
                          }}
                        >
                          <Avatar
                            size="mini"
                            image={userProfile?.photoURL}
                            name={userProfile?.displayName}
                          />
                        </Link>
                      </Menu.Item>
                    </Menu.Menu>
                  ) : (
                    <Button
                      size="tiny"
                      inverted
                      as="a"
                      basic
                      onClick={() => navigate(ROUTES.signin)}
                      style={{marginRight: '1em'}}
                    >
                      Login
                    </Button>
                  )}
                  <Icon
                    inverted
                    name="sidebar"
                    style={{fontSize: '1.25rem', marginTop: '5px'}}
                    onClick={handleToggle}
                  />
                </Menu.Item>
              </Menu>
            </div>
          </Segment>
          {layout()}
        </MobilePageContainer>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const MobilePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-context: flex-start;
  align-items: flex-start;
  align-content: stretch;
  min-height: ${window.screen.height}px;
  height: 100%;
  width: 100%;
`;

const LayoutContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: ${props => props.theme.light.background.color};
  color: ${props => props.theme.light.font.primary.color};
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-context: flex-start;
  align-items: flex-start;
  align-content: stretch;
`;

const LayoutWrapper = styled.div<{fullScreen: boolean}>`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 20px auto 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-context: flex-start;
  align-items: flex-start;
  align-content: stretch;
  @media (max-width: 768px) {
    padding-bottom: 40px;
  }
`;
