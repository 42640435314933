import Joi from "joi";

export const product_schema = Joi.object({
  sku: Joi.string().required(),
  lot: Joi.string().required(),
  name: Joi.string().max(45).required(),
  reduceQty: Joi.boolean().optional(),
  taxable: Joi.boolean().optional(),
  shipping: Joi.boolean().optional(),
  qty: Joi.when("reduceQty", {
    is: Joi.exist(),
    then: Joi.number().integer().min(1).required(),
    otherwise: Joi.optional(),
  }),
  price: Joi.string().regex(/[\d.-]/),
  type: Joi.string().required(),
});
