import React from 'react';
import styled from 'styled-components';
import {PageFooter, DebugBar} from 'components';

export const DesktopLayout = ({children = null, fullScreen = false}) => {
  return (
      <PageContainer>
        <DebugBar/>
        <TopBar id="top-bar" />
        <LayoutContainer id="layout-container" fullScreen={fullScreen}>
          {children}
        </LayoutContainer>
        <PageFooter />
      </PageContainer>
  );
};

const TopBar = styled.div`
  background-color: ${props => props.theme.primaryColor};
  width: 100%;
  height: 10px;
  min-height: 10px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-context: flex-start;
  align-items: flex-start;
  align-content: stretch;
  height: 100%;
  position: relative;
  margin: 0;
`;

const LayoutContainer = styled.div<{fullScreen: boolean}>`
  width: 100%;
  flex-grow: 1;
  margin-top: ${props => (props.fullScreen ? '0' : '40')}px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-context: flex-start;
  align-items: flex-start;
  align-content: stretch;
`;
