import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById("kwixl-admin-app")
);

/*
const root = ReactDOM.createRoot(
  document.getElementById('kwixl-app') as HTMLElement
);
root.render(
    <App />
);
*/
if (process.env.NODE_ENV === 'development') console.log('ENV', process.env);
