import React from 'react';
import {Link, Navigate, useLocation, Outlet} from 'react-router-dom';
import {Message} from 'semantic-ui-react';
import {useFirebaseContext} from 'hooks';
import {Loading} from '@kwixl/elements';
import {ROUTES} from 'routes';
import {firestore} from '@kwixl/interface';

const UserRoles = firestore.User.UserRoles;

export const AdminRoute = ({
  redirect = ROUTES.signin,
}) => {
  const {firebaseUser, userProfile} = useFirebaseContext();

  const location = useLocation();

  if (!firebaseUser) {
    return <Navigate to={redirect} state={{from: location}} />;
  }

  if (!userProfile) {
    return <Loading />;
  }

  if (!userProfile?.verified) {
    return <Navigate to={ROUTES.signin} state={{from: location}} />;
  }

  //window.setTimeout(() => window.scrollTo(0, 0), 150);

  if (userProfile.role !== UserRoles.admin) {
    return (
      <div style={{textAlign: 'center'}}>
        <Message negative style={{marginTop: '5rem'}}>
          The page you requested cannot be accessed at this time.
        </Message>
        <p>
          <Link to={ROUTES.home}>Return to {process.env.REACT_APP_NAME}</Link>
        </p>
      </div>
    );
  }

  return <Outlet />;
};
