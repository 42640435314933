import {useRef, useEffect, useReducer} from 'react';
import {Table} from 'semantic-ui-react';
import {withAuthenticatedPage} from 'hoc';
import {DateTime} from 'luxon';
import {
    collection,
    onSnapshot,
    query,
    orderBy,
} from 'firebase/firestore';
import {useFirebaseContext} from 'hooks';
import {reducer} from 'lib';
import {EditApplicationModal} from './components';

const initialState = {
    updated: null,
    application: null,
}

export const Main = withAuthenticatedPage(({ returnHeader, header2 }) => {
    
    const {db, updateCollectionArray} = useFirebaseContext();

    const unsubApplications = useRef(() => {});
    const applicationsRef = useRef([]);

    const [{
        updated,
        application,
    }, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        unsubApplications.current = onSnapshot(query(collection(db, 'applications'), orderBy('createdAt', 'desc')), applicationsListener);
        return unsubApplications.current;
    },[]);

    const applicationsListener = snap => {
        updateCollectionArray(snap, applicationsRef.current, setApplications);
    }

    const setApplications = snap => {
        applicationsRef.current = snap;
        dispatch({ updated: new Date() });
    }

    return (
        <>
            {returnHeader('Kwixl Seller Applications Admin')}
            <EditApplicationModal 
                value={application} 
                open={!!application} 
                onClose={() => dispatch({ application: null })}
            />
            <Table stackable selectable>
                <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Application Date
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Organization
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Last Update
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                    {applicationsRef.current.map(row => (
                        <Table.Row onClick={() => dispatch({ application: row })}>
                            <Table.Cell>
                                {row.get('createdAt') ? DateTime.fromJSDate(row.get('createdAt').toDate()).toLocaleString(DateTime.DATETIME_FULL) : null}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('status')}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('orgName')}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('orgId')}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('updatedAt') ? DateTime.fromJSDate(row.get('updatedAt').toDate()).toLocaleString(DateTime.DATETIME_FULL) : null}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    )

})
