/**
 * Generic schema validator for forms
 */

export default (data, schema) => {
  const {error} = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
  });

  let errors = null;

  (error?.details || []).forEach(({message, path, type, context}) => {
    const {key} = context;

    if (!errors || !errors[key]) {
      if (!errors) {
        errors = {};
      }
      switch (type) {
        case 'any.empty':
          errors[key] = `"${key}" cannot be blank.`;
          break;
        default:
          errors[key] = message;
          break;
      }
    }
  });

  return errors;
};
