import { flash } from "lib";

const formatCurrency = (value, len = 2) => {
  if (!value) value = "";
  let s = value.replace(/[^\d.-]/g, "");
  if (s === "") return s;
  let parts = s.split(".");
  s = `${parts[0]}`;
  if (parts.length > 1) {
    s += `.${parts[1].substring(0, len)}`;
  }
  return s;
};

const copyLink = (url, message = "Link copied to clipboard!") => {
  const el = document.createElement("textarea");
  el.value = url;
  document.body.appendChild(el);
  el.select();
  el.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(el);
  flash.success(message);
};

export { formatCurrency, copyLink };
