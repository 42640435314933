import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {AdminRoute} from 'components';
import {Main} from './Main';
import {View} from './View';

import {ROUTES} from 'routes';

export const routes = () => (
  <Routes>
    <Route path="/categories" element={<AdminRoute/>}>
      <Route path="/categories" element={<Main/>}/>
    </Route>
    <Route path="/category" element={<AdminRoute/>}>
      <Route path="/category/:id" element={<View/>}/>
    </Route>
  </Routes>
);

export default routes;
