import React, {useReducer, useEffect} from 'react';
import {Button, Icon, Modal, Form} from 'semantic-ui-react';
import {
    doc,
    serverTimestamp,
    setDoc,
    getDoc,
} from 'firebase/firestore';
import {useFirebaseContext} from 'hooks';
import {GenericObject, CustomDateInput, RequiredField} from '@kwixl/elements';
import {flash, getDocId, reducer, validate} from 'lib';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {DateTime} from 'luxon';
import { demo_schema } from 'schema';

interface Props {
    value?: GenericObject;
}

const initialState = {
    open: false,
    name: '',
    url:  '',
    email: '',
    startAt: DateTime.now().toJSDate(),
    errors: {},
}

export const EditDemoModal = ({ value = {} }: Props) => {

    const [{
        open,
        name,
        email,
        url,
        errors,
        startAt,
    }, dispatch] = useReducer(reducer, initialState);

    const {db} = useFirebaseContext();

    const handleChange = (e, {name, value }) => {
        delete errors[name];
        dispatch({ [name]: value, errors });
    }

    const handleClose = () => {
        dispatch({ open: false });
    }

    const save = async () => {

        const formErrors = validate({
            name,
            email,
            url,
        }, 
        demo_schema);

        if (Object.keys(formErrors  || {}).length > 0) {
            dispatch({ errors: formErrors });
            flash.error('There was an error creating the demo session.')
            return;
        }

        try {
            const createdAt  = value?.id ? undefined : serverTimestamp();
            await setDoc(doc(db, 'demo', value?.id || getDocId()), {
                name,
                email,
                url,
                startAt,
                createdAt,
                updatedAt: serverTimestamp(),
            }, {
                merge: true
            });
            flash.success('Demo session created.');
            handleClose();
        } catch (err: any) {
            flash.error(err.message);
        }
    }

    return (
        <Modal
            open={open}
            onOpen={() => dispatch({ open: true })}
            onClose={handleClose}
            trigger={
                value?.id ? 
                    <NameLink>{value?.name}</NameLink>
                    : <Button 
                    icon
                    color='blue'
                >
                    <Icon name="plus"/>  Add Demo Session
                </Button>
            }
        >
            <Modal.Header>
                {value?.id ? 'Edit' : 'Add'} Demo Session
            </Modal.Header>
            <Modal.Content>
                <Form error={Object.keys(errors).length > 0}>
                    <Form.Field>
                        <RequiredField error={!!errors['startAt']} message={errors.startAt}>
                        <Label>Session Time</Label>
                        <DatePicker
                            customInput={<CustomDateInput />}
                            showTimeSelect
                            selected={startAt}
                            onChange={date =>
                                handleChange(null, {name: 'startAt', value: date})
                            }
                            dateFormat="MM/dd/yyyy h:mm a"
                            minDate={new Date()}
                            maxDate={DateTime.fromJSDate(new Date()).plus({days: 30}).toJSDate()}
                        />
                        </RequiredField>
                    </Form.Field>
                    <Form.Field>
                        <RequiredField error={!!errors['name']} message={errors.name}>
                            <Form.Input
                                name="name"
                                label="Customer Name"
                                value={name}
                                onChange={handleChange}
                                fluid
                            />
                        </RequiredField>
                    </Form.Field>
                    <Form.Field>
                        <RequiredField error={!!errors['email']} message={errors.email}>
                            <Form.Input
                                name="email"
                                label="Email"
                                value={email}
                                onChange={handleChange}
                                fluid
                            />
                        </RequiredField>    
                    </Form.Field>
                    <Form.Field>
                        <RequiredField error={!!errors['url']} message={errors.url}>
                            <Form.Input
                                name="url"
                                label="Meeting Link"
                                value={url}
                                onChange={handleChange}
                                fluid
                            />
                        </RequiredField>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    icon
                    color='red'
                    onClick={() => handleClose()}
                >
                    <Icon name="close"/> Cancel
                </Button>
                <Button
                    icon
                    color='green'
                    onClick={() => save()}
                >
                    <Icon name="check"/> Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const NameLink = styled.a`
    cursor: pointer;
    & hover: {
        text-decoration: underline;
    }
`

const Label = styled.span`
    display: block;
    font-weight: bolder;
`