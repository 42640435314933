import React, {useReducer, useEffect, useRef} from 'react';
import {Button, Icon, Modal, Form} from 'semantic-ui-react';
import {
    doc,
    serverTimestamp,
    setDoc,
    onSnapshot,
    collection,
    getDocs,
} from 'firebase/firestore';
import {useFirebaseContext} from 'hooks';
import {GenericObject, RequiredField} from '@kwixl/elements';
import {flash, reducer, validate} from 'lib';
import 'react-datepicker/dist/react-datepicker.css';
import { application_schema } from 'schema';

interface Props {
    value?: GenericObject;
    open?: boolean;
    onClose?: () => void;
}

const initialState = {
    isOpen: false,
    status: 'pending',
    reason: '',
    orgId: '',
    errors: {},
}

export const EditApplicationModal = ({ value = {}, open = false, onClose = () => {} }: Props) => {

    const [{
        isOpen,
        status,
        orgId,
        reason,
        errors,
    }, dispatch] = useReducer(reducer, initialState);

    const {
        db, 
        updateCollectionArray,
        firebaseUser,
        addMessage,
        organizationMembersRef,
    } = useFirebaseContext();

    const unsubOrganizations = useRef(() => {});
    const organizations = useRef([]);

    useEffect(() => {
        unsubOrganizations.current = onSnapshot(collection(db, 'organizations'), organizationsListener);
        return unsubOrganizations.current;
    },[]);

    useEffect(() => {
        dispatch({ isOpen: open });
        if (value) {
            dispatch({
                orgId: value.get('orgId'),
                status: value.get('status'),
                reason: value.get('status') === 'denied' ? value.get('reason') || null : null,
            });
        }
    },[open, value]);

    const organizationsListener = snap => {
        updateCollectionArray(snap, organizations.current, setOrganizations);
    }

    const setOrganizations = snap => {
        organizations.current = snap;
    }

    const handleChange = (e, {name, value }) => {
        delete errors[name];
        dispatch({ [name]: value, errors });
        if (name === 'status' && value !== 'denied' && reason) {
            dispatch({ reason: null });
        }
    }

    const handleClose = () => {
        onClose();
        dispatch(initialState);
    }

    const save = async () => {

        const formErrors = validate({
            status,
            orgId,
            reason,
        }, 
        application_schema);

        if (Object.keys(formErrors  || {}).length > 0) {
            dispatch({ errors: formErrors });
            flash.error('There was an error creating the application. Please check the form and try again.')
            return;
        }

        try {
            const org = organizations.current.find(org => org.id === orgId);
            const payload: GenericObject = {
                status,
                orgId,
                reason: reason || null,
                orgName: org.get('name'),
                updatedAt: serverTimestamp(),
                updatedBy: firebaseUser.uid,
            }
            if (!value?.id) {
                payload.createdAt = serverTimestamp();
                payload.createdBy = firebaseUser.uid;
            }
            await setDoc(doc(db, 'applications', orgId), 
                payload, 
                {
                    merge: true
                });
            flash.success(`Application ${value?.id ? 'updated' : 'created'}.`);
            handleClose();
            if (value && !value.get('notifiedAt')) {
                let data: GenericObject = {}; 
                let template = '';
                switch (status) {
                    case 'approved':
                        template = 'application:approved';
                        break;
                    case 'denied':
                        template = 'application:denied';
                        data = { reason };
                        break;
                }
                if (template) {
                    const members = await getDocs(organizationMembersRef(orgId))
                    const admins = members.docs.filter(member => member.get('role') === 'admin');
                    for (const admin of admins) {
                        const result = await addMessage({
                            createdAt: serverTimestamp(),
                            uid: admin.id,
                            template,
                            data,
                        })
                    }
                }
            }
        } catch (err: any) {
            flash.error(err.message);
        }
    }

    return (
        <Modal
            open={isOpen}
            onOpen={() => dispatch({ isOpen: true })}
            onClose={handleClose}
            trigger={
                <Button 
                    icon
                    color='blue'
                >
                    <Icon name="plus"/>  Add Seller Application
                </Button>
            }
        >
            <Modal.Header>
                {value?.id ? 'Edit' : 'Add'} Seller Application {value?.id && <>for {value.get('orgName')}</>}
            </Modal.Header>
            <Modal.Content>
                <Form error={Object.keys(errors).length > 0}>

                    <Form.Field>
                        <RequiredField error={!!errors['name']} message={errors.name}>
                            <Form.Select
                                name="status"
                                label="Application Status"
                                value={status}
                                onChange={handleChange}
                                options={[
                                    {
                                        key: 'pending',
                                        value: 'pending',
                                        text: 'Pending',
                                    },
                                    {
                                        key: 'approved',
                                        value: 'approved',
                                        text: 'Approved',
                                    },
                                    {
                                        key: 'denied',
                                        value: 'denied',
                                        text: 'Denied',
                                    },
                                ]}
                            />
                        </RequiredField>
                        {!value?.id && (
                            <RequiredField error={!!errors['name']} message={errors.name}>
                                <Form.Select
                                    search
                                    name="orgId"
                                    label="Organization"
                                    value={orgId}
                                    onChange={handleChange}
                                    options={organizations.current.map(org => (
                                        {
                                            key: org.id,
                                            text: org.get('name') + ' (' + org.id + ')',
                                            value: org.id,
                                        }
                                    ))}
                                />
                            </RequiredField>
                        )}
                    </Form.Field>
                    {status === 'denied' && (
                        <Form.Field>
                            <RequiredField error={!!errors['reason']} message={errors.reason}>
                                <Form.TextArea
                                    label="Reason for Denial"
                                    name="reason"
                                    onChange={handleChange}
                                    value={reason}
                                />
                            </RequiredField>
                        </Form.Field>
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    icon
                    color='red'
                    onClick={() => handleClose()}
                >
                    <Icon name="close"/> Cancel
                </Button>
                <Button
                    icon
                    color='green'
                    onClick={() => save()}
                >
                    <Icon name="check"/> Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

