import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {withAuthenticatedPage} from 'hoc';
import {
    getDoc,
    doc,
    DocumentSnapshot,
} from 'firebase/firestore';
import {useFirebaseContext} from 'hooks';
import {Loading} from '@kwixl/elements';

export const View = withAuthenticatedPage(({ returnHeader, header2, header4 }) => {
 
    const { id } = useParams();
    
    const {db} = useFirebaseContext();

    const [event, setEvent] = useState<DocumentSnapshot | null>(null);

    useEffect(() => {
        (async () => {
            if (!id) return;
            const result = await getDoc(doc(db, 'events', id));
            setEvent(result);
        })()
    },[id]);

    if (!event) return <Loading/>;

    return (
        <>
            {returnHeader(event.get('name'), '/events')}
            {header4(event.get('presentedBy'))}
        </>
    )
    

})