import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {AdminRoute} from 'components';
import {List} from './List';
import {View} from './View';
import {Monitor} from './Monitor';

export const routes = () => (
  <Routes>
    <Route path="/events" element={<AdminRoute/>}>
      <Route path="/events" element={<List/>}/>
    </Route>
    <Route path="/event" element={<AdminRoute/>}>
      <Route path="/event/:id" element={<View/>}/>
      <Route path="/event/:id/monitor" element={<Monitor/>}/>
    </Route>
  </Routes>
);

export default routes;
