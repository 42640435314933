import React from 'react';
import {Header, Message} from 'semantic-ui-react';
import {EmailLogin} from 'components';
import {GenericObject} from 'lib';

interface Props {
  onSuccess: (data?: GenericObject) => void;
  state?: GenericObject;
}

export const LoginForm = ({onSuccess = () => {}, state = null}: Props) => (
  <>
    <Header as="h2" style={{textAlign: 'center'}}>
      Sign-In
    </Header>
    {state?.message && <Message info>{state.message}</Message>}
    <EmailLogin onSuccess={onSuccess}/>
  </>
);
