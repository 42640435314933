import React from 'react';
import { DesktopLayout, MobileLayout } from 'layouts';
import { withBasePage } from 'hoc';
import { useSessionContext } from 'hooks';

export const withPublicPage = (
    WrappedComponent, 
    fullScreen = false
) => {

    return withBasePage(props => {

        const { mobile } = useSessionContext();

        return mobile
            ?   <MobileLayout fullScreen={fullScreen}>
                    <WrappedComponent
                        {...props}
                    />
                </MobileLayout>
            :   <DesktopLayout fullScreen={fullScreen}>
                    <WrappedComponent
                        {...props}
                    />
                </DesktopLayout>
            
    });

}

//withPublicPage.whyDidYouRender = true;
