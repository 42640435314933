import React from 'react';
import {useFirebaseContext, useSessionContext} from 'hooks';
import styled from 'styled-components';

export const DebugBar = () => {
  const {demo} = useSessionContext();

  const {firebaseUser, userProfile, organization} =
    useFirebaseContext();

  const bar = () => {
    return (
      <Bar>
        <Span>Version: {}</Span>
        <Span>{firebaseUser.displayName}</Span>
        <Span>UID: {firebaseUser?.uid}</Span>
        <Span>Role: {userProfile?.role}</Span>
        <Span>
          Org: {organization?.id} - {organization?.name}
        </Span>
      </Bar>
    );
  };

  return process.env.NODE_ENV !== 'production' && process.env.REACT_APP_STAGE !== 'stage' && !demo && firebaseUser
    ? bar()
    : null;
};

const Bar = styled.div`
  background-color: navy;
  color: white;
  padding: 5px;
  width: 100%;
  display: block;
  margin: 0;
`;

const Span = styled.span`
  margin-right: 2em;
`;
