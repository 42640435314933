import React from 'react';
import {Image} from 'semantic-ui-react';
import styled from 'styled-components';
//import {useMobile} from '..';

interface Props {
  image: string;
  name: string;
  size?: string;
  centered?: boolean;
}

export const Avatar = ({
  image = '',
  name = '',
  size = 'mini',
  centered = false,
}: Props) => {

    const useMobile = false;
    
  const initial = name?.substring(0, 1) || null;

  const userImage = image?.indexOf('http') > -1 ? image : null;

  if (!userImage && !initial) return null;

  return userImage ? (
    <SizedAvatarImage
      src={userImage}
      size={size}
      circular
      centered={centered ? true : false}
    />
  ) : (
    (() => {
      switch (size) {
        case 'large':
          return <LargeAvatarContainer>{initial}</LargeAvatarContainer>;
        default:
          return useMobile ? (
            <MobileAvatarContainer>{initial}</MobileAvatarContainer>
          ) : (
            <DefaultAvatarContainer>{initial}</DefaultAvatarContainer>
          );
      }
    })()
  );
};

const DefaultAvatarContainer = styled.div`
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  background-color: #03a9f4;
  color: white;
  font-size: 25px;
  line-height: 33px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
`;

const LargeAvatarContainer = styled(DefaultAvatarContainer)`
  width: 75px;
  height: 75px;
  border-radius: 38px;
  font-size: 65px;
  line-height: 76px;
  font-weight: bolder;
`;

const MobileAvatarContainer = styled(DefaultAvatarContainer)`
  width: 2rem;
  height: 2rem;
  line-height: 30px;
`;

const SizedAvatarImage = styled(Image)`
  border: 2px solid white;
  margin-right: 20px;
  background-color: transparent;
  margin: ${props => (props.centered ? '0 auto' : '0')};
`;
