import {Table, Icon} from 'semantic-ui-react';
import {withAuthenticatedPage} from 'hoc';
import {
    query,
    orderBy,
} from 'firebase/firestore';
import {useFirebaseContext} from 'hooks';
import {useCollection} from 'react-firebase-hooks/firestore';

export const Main = withAuthenticatedPage(({ returnHeader }) => {
    
    const {
        usersRef, 
        defaultListenerOptions,
    } = useFirebaseContext();

    const [users] = useCollection(
        query(
            usersRef,
            orderBy('lastName', 'desc')
        ),
        defaultListenerOptions,
    )

    return (
        <>
            {returnHeader('Kwixl Users')}
            <Table stackable selectable>
                <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Handle
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Organization
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Verified
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                    {users?.docs?.map(row => (
                        <Table.Row negative={row.get('deletedAt')}>
                            <Table.Cell>
                                {row.get('lastName')}, {row.get('firstName')}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('email')}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('handle')}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('organization.name') || row.get('organization.id') || row.get('orgId')}
                            </Table.Cell>
                            <Table.Cell>
                                {row.get('verified') ? <Icon name="check" color="green"/> : <Icon name="cancel" color="red" />}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    )

})
