import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {AdminRoute} from 'components';
import {Main} from './Main';

import {ROUTES} from 'routes';

export const routes = () => (
  <Routes>
    <Route path={ROUTES.applications} element={<AdminRoute/>}>
      <Route path={ROUTES.applications} element={<Main/>}/>
    </Route>
  </Routes>
);

export default routes;
