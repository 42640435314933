import React, { useEffect, useRef, useState } from 'react';
import {useParams} from 'react-router-dom';
import {withAuthenticatedPage} from 'hoc';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import {useFirebaseContext} from 'hooks';
import {
    doc,
    onSnapshot,
    DocumentSnapshot,
    updateDoc,
    serverTimestamp,
    query,
    where,
    collection,
    orderBy,
} from 'firebase/firestore';
import {Loading, Confirm} from '@kwixl/elements';
import {firestore} from '@kwixl/interface';
import {Button, Icon} from 'semantic-ui-react';
import {flash} from 'lib';
import {DateTime} from 'luxon';
import {ChatMessage} from 'components';

const EventStatus = firestore.Event.EventStatus;

export const Monitor = withAuthenticatedPage(({ returnHeader }) => {
    
    const {id} = useParams();

    const {db, firebaseUser, updateCollectionArray} = useFirebaseContext();

    const [event, setEvent] = useState<DocumentSnapshot | null>(null);
    const [chats, setChats] = useState<DocumentSnapshot[]>([]);

    const eventRef = useRef(() => {});
    const chatRef = useRef(() => {});
    const chatsRef = useRef<DocumentSnapshot[]>([]);

    useEffect(() => {
        return () => {
            eventRef.current();
            chatRef.current();
        }
    },[]);

    useEffect(() => {
        console.log('id', id, 'db', db)
        if (!id || !db) return;
        console.log('here');
        eventRef.current = onSnapshot(doc(db, 'events', id), snap => setEvent(snap));
        chatRef.current = onSnapshot(
            query(
                collection(db, `events/${id}/chats`),
                orderBy('createdAt', 'desc'),
            )
        , chatListener);
        console.log('chatref', chatRef.current);
    },[id, db]);

    const chatListener = snap => {
        console.log('at chat')
        updateCollectionArray(snap, chatsRef.current, updateChat);
        console.log('after chat')
    }

    const updateChat = data => {
        console.log('CHATS', data);
        chatsRef.current = data;
        setChats(data);
    }

    const stopEvent = async () => {
        try {
            await updateDoc(doc(db, 'events', event.id), { 
                endAt: serverTimestamp(),
                lockedAt: serverTimestamp(),
                lockedBy: firebaseUser.uid,
                status: EventStatus.locked,
            });
            flash.success('Event has been stopped.');
        } catch (err) {
            flash.error(err.message);
        }
    }
    
    if (!event) return <Loading/>


    let canStop = false;
    try {
        canStop = 
            ([EventStatus.pending, EventStatus.active].includes(event.get('status'))
            || DateTime.utc().toMillis() < DateTime.fromJSDate(event.get('endAt').toDate()).toMillis())
            && DateTime.utc().toMillis() >= DateTime.fromJSDate(event.get('startAt').toDate()).toMillis();
    } catch (err) {
        console.log(err);
    }

    return (
        <PageWrapper>
            <Row>
                {returnHeader(event.get('name'), '/events')}
                { /*canStop && 
                    <Confirm
                        trigger={
                            <Button
                                icon
                                color="red"
                            >
                                <Icon name="stop"/> Stop Event
                            </Button>
                        }
                        onConfirm={() => stopEvent()}
                    />
                    */}
            </Row>
            <EventWrapper>
                <Column>
                    <ReactPlayer
                        id="video-player"
                        playsinline={true}
                        controls={true}
                        pip={false}
                        className="react-player"
                        width={'100%'}
                        url={event.get('stream.url')}
                        playing={true}
                        config={{
                            youtube: {
                                playerVars: {
                                autoplay: 1,
                                controls: 1,
                                fs: 0,
                                modestbranding: 1,
                                playsinline: 1,
                                },
                            },
                            facebook: {
                                appId: process.env.REACT_APP_FB_ID,
                                playerId: 'fb-player',
                            },
                            vimeo: {
                                playerOptions: {
                                autoplay: true,
                                muted: false,
                                controls: false,
                                },
                            },
                        }}
                    />
                </Column>
                <Column>
                    { chats.map(chat => <ChatMessage chat={chat}/>)}
                </Column>
            </EventWrapper>
        </PageWrapper>
    )
});

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const EventWrapper = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`

const Row = styled.div`
    flex: 0 1 0;
    padding: 10px;
`
const Column = styled.div`
    flex: 1 1 49%;
`