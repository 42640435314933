import Joi from "joi";

export const demo_schema = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: false })
    .required()
    .messages({
      "string.email": `"Email" must be a valid email address`,
    }),
  name: Joi.string().min(5).max(50).required().messages({
    "string.min": `"Name" should have a minimum length of {#limit} characters`,
    "string.max": `"Name" should have a maximum length of {#limit} characters`,
    "any.required": `"Name" is a required field`,
  }),
  url: Joi.string().min(8).required()
});
