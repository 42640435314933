import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {signOut} from 'firebase/auth';
import {useFirebaseContext} from 'hooks';

export const SignOut = () => {

  const {auth} = useFirebaseContext();

  const navigate = useNavigate();

  useEffect(() => {
    signOut(auth).then(() => {
      navigate('/');
    });
  }, []);

  return null;
};
