import React, {useReducer, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {withAuthenticatedPage} from 'hoc';
import {Table, Icon, Button} from 'semantic-ui-react';
import {reducer} from 'lib';
import {useFirebaseContext} from 'hooks';
import {
    query,
    where,
    orderBy,
    onSnapshot,
    collection,
    DocumentSnapshot,
    limit,
    startAfter,
} from 'firebase/firestore';
import {FullDate} from '@kwixl/elements';

const initialState = {
    events: [],
    page: 0,
    atEnd: false,
}

export const List = withAuthenticatedPage(({ returnHeader }) => {

    const {db, updateCollectionArray} = useFirebaseContext();

    const [{ 
        events,
        page,
        atEnd,
    }, dispatch] = useReducer(reducer, initialState);

    const unsubEvents = useRef(() => {});
    const eventsRef = useRef<DocumentSnapshot[]>([]);

    useEffect(() => {
        return unsubEvents.current;
    },[]);

    useEffect(() => {
        (async () => {
            if (!db) return;
            let ref = 
                query(
                    collection(db, 'events'), 
                    orderBy('startAt', 'desc'),
                    limit(25),
                );
            if (page > 0 && events) {
                ref = query(ref, startAfter(events[events.length-1]))
            }
            unsubEvents.current = onSnapshot(ref, eventListener);
        })()
    },[db, page]);

    const eventListener = snap => {
        if (page > 0 && snap.docChanges.length < 1) {
            dispatch({ atEnd: true });
            return;
        }
        updateCollectionArray(snap, eventsRef.current, events => {
            eventsRef.current = events;
            dispatch({ events })
        });
    }

    return (
        <>
            {returnHeader('Events', '/')}
            <Table>
                <Table.Body>
                    { events.map(event => 
                        <Table.Row>
                            <Table.Cell>
                                {event.id}
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`/event/${event.id}`}>{event.get('name')}</Link>
                            </Table.Cell>
                            <Table.Cell>
                                {event.get('presentedBy')}
                            </Table.Cell>
                            <Table.Cell>
                                <FullDate date={event.get('startAt').toDate()}/>
                            </Table.Cell>
                            <Table.Cell>
                                {event.get('status')}
                            </Table.Cell>
                            <Table.Cell>
                                {event.get('stream.url') && 
                                    <Link to={`/event/${event.id}/monitor`}>
                                        <Icon link name="video camera"/>
                                    </Link>
                                }
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            {(events.length > 24 && !atEnd) && 
                <Button
                    icon
                    onClick={() => dispatch({ page: page+1 })}
                >
                    <Icon name="plus"/> Load More...
                </Button>
            }
        </>
    )
});