import React, {useEffect} from 'react';
import {useNavigate, useLocation, Location} from 'react-router-dom';
import {Container} from 'semantic-ui-react';
import {LoginForm} from 'components';
import {useFirebaseContext} from 'hooks';
import {withPublicPage} from 'hoc';
import {GenericObject, flash} from 'lib';
import {isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';
import styled from 'styled-components';

export const SignIn = withPublicPage(() => {

  console.log('AT SIGNIN')

  const {
    firebaseUser, 
    userHome, 
    auth, 
    setFirebaseUser
  } = useFirebaseContext();

  const navigate = useNavigate();

  const location: Location = useLocation();

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const email = window.localStorage.getItem('signInEmail');
        if (!email) {
          navigate('/');
          return;
        }
        try {
          const result = await signInWithEmailLink(
            auth,
            email,
            window.location.href
          );
          window.localStorage.removeItem('emailForSignIn');
          if (result?.user) setFirebaseUser(result.user);
          const params = new URLSearchParams(location.search);
          navigate(params.get('to') || '/me');
        } catch (err) {
          flash.error(err.message);
          navigate('/');
        }
      }
    })();
  }, [location.search, navigate]);

  const returnToPage = (data?: GenericObject) => {
    let url = data?.home || userHome || '/me';
    try {
      const state: {[key:string]:any} = location.state || {};
      if (location && location.state) {
        const {
          from: {
            pathname
          },
        } = state;
        if (pathname?.substring(0, 1) === '/') {
          url = pathname;
        }
      }
    } catch (err) {
      console.error(err.message);
    }
    navigate(url, {replace: true, state: location.state || {}});
  };

  if (firebaseUser) {
    returnToPage();
  }

  return (
    <Container>
      <FormContainer>
        <LoginForm onSuccess={returnToPage} state={location?.state} />
      </FormContainer>
    </Container>
  );
});

const FormContainer = styled.div`
  width: 98%;
  margin: 0 auto 40px auto;
  @media only screen and (min-device-width: 768px) {
    max-width: 350px;
  }
`;
