import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const SiteTitle = () => 
    <Title>
      <Link to="/" style={{color:'inherit'}}>
        <Logo src="/android-icon-48x48.png"/>
        <Bold>{ process.env.REACT_APP_NAME }</Bold> Live <Bold>Video</Bold> Commerce
      </Link>
    </Title>    

const Title = styled.div`
  text-transform: uppercase;
  font-weight: 300;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const Logo = styled.img`
  height: 18px;
  margin-right: 5px;
`;