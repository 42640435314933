import React from 'react';
import {Menu, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {useFirebaseContext} from 'hooks';
import {ROUTES} from 'routes';
import {firestore} from '@kwixl/interface';
import {useMobile} from '@kwixl/elements';

const UserRoles = firestore.User.UserRoles;

export const AuthenticatedMenu = () => {
  const {userProfile, userCanSell, userHome = '/me'} = useFirebaseContext();

  const AuthenticatedMenuItems = () => {
    return (
      <>
        <Menu.Item header>Me</Menu.Item>
        <Menu.Item>
          <Link to={userHome}>
            <Icon name="sliders horizontal" /> My Control Panel
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/my/profile">
            <Icon name="user outline" /> My Profile
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/my/invoices">
            <Icon name="file alternate outline" /> My Invoices
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/profile/login">
            <Icon name="lock" /> Change My Login
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/my/subscriptions">
            <Icon name="heart" /> My Subscriptions
          </Link>
        </Menu.Item>
        {/*<Menu.Item>
          <Link to="/my/paymethods">
            <Icon name="money" /> My Payment Method
          </Link>
        </Menu.Item>*/}
        <Menu.Item>
          <Link to={ROUTES.signout}>
            <Icon name="sign out" />
            Sign-out
          </Link>
        </Menu.Item>

        {userCanSell &&
          (userProfile?.role === UserRoles.admin ||
            userProfile?.role === UserRoles.presenter) && (
            <>
              <Menu.Item />
              <Menu.Item header style={{color: '#fffffff', fontSize: '1em'}}>
                Selling
              </Menu.Item>
              {userProfile?.role === UserRoles.presenter && (
                <Menu.Item>
                  <Link to="/presenter/events">
                    <Icon name="file outline" />
                    Events To Present
                  </Link>
                </Menu.Item>
              )}
              {userProfile.role === UserRoles.admin && (
                <>
                  <Menu.Item>
                    <Link to="/cp/event">
                      <Icon name="file outline" /> Event Manager
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/cp/org">
                      <Icon name="building outline" /> My Organization
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/cp/customers">
                      <Icon name="users" /> Customers
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/cp/settings">
                      <Icon name="cog" /> Settings
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/cp/billing">
                      <Icon name="money" /> Billing
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/support">
                      <Icon name="help circle" /> Help &amp; Guides
                    </Link>
                  </Menu.Item>
                </>
              )}
              {!userCanSell && (
                <Menu.Item>
                  <Link to="/account/upgrade">
                    Sell on {process.env.REACT_APP_NAME}!
                  </Link>
                </Menu.Item>
              )}
            </>
          )}
      </>
    );
  };

  return useMobile ? (
    <AuthenticatedMenuItems />
  ) : (
    <Menu vertical fluid inverted>
      <AuthenticatedMenuItems />
    </Menu>
  );
};

