import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Sticky, Menu, Dropdown, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {SiteTitle} from 'components';
import {Avatar} from '@kwixl/elements';
import {
  useSessionContext,
  useFirebaseContext,
} from 'hooks';
import {ROUTES} from 'routes';

export const TopMenu = ({context, onMenu}) => {
  const navigate = useNavigate();

  const {firebaseUser, userProfile, userCanSell, userHome} =
    useFirebaseContext();

  const {demo} = useSessionContext();

  const notificationCount = 0;
  const messageCount = 0;
  
  if (!context) return null;

  return (
    <div style={{flex: 0, width: '100%'}}>
      <Sticky id="top-menu" context={context} style={{zIndex: '999'}}>
        <StyledMenu id="main-menu" attached="top" secondary pointing borderless>
          <MenuItem>
            <SiteTitle />
          </MenuItem>
          <Menu.Menu position="right" style={{zIndex: 500}}>
            {firebaseUser && (
              <>
                <MenuItem>
                  <Icon
                    link
                    onClick={() => {}}
                    name="mail outline"
                    style={{marginTop: '.6rem'}}
                  />
                  {messageCount > 0 && (
                    <NotificationsBadge>{messageCount}</NotificationsBadge>
                  )}
                  <Icon
                    link
                    onClick={() => {}}
                    name="bell outline"
                    style={{marginTop: '.6rem'}}
                  />
                  {notificationCount > 0 && (
                    <NotificationsBadge>{notificationCount}</NotificationsBadge>
                  )}
                </MenuItem>
                <MenuItem>
                  <Dropdown
                    icon={null}
                    trigger={
                      <Avatar
                        image={userProfile?.photoURL || firebaseUser?.photoURL}
                        name={firebaseUser?.displayName}
                      />
                    }
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <MenuLink to={ROUTES.signout}>
                          <Icon name="sign-out" /> sign-out
                        </MenuLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </MenuItem>
                <MenuItem>
                  <Icon
                    link
                    name="sidebar"
                    style={{fontSize: '1.25rem', marginTop: '5px'}}
                    onClick={onMenu}
                  />
                </MenuItem>
              </>
            )}
          </Menu.Menu>
        </StyledMenu>
      </Sticky>
    </div>
  );
};

const StyledMenu = styled(Menu)`
  boxshadow: 0 0 5px 2px rgba(0, 0, 0, 0.33);
  fontsize: 1.5rem;
  padding: 0 1em;
  backgroundcolor: #ffffff;
  color: #000000;
`;
const NotificationsBadge = styled.span`
  color: rgb(255, 255, 255);
  background-color: red;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  padding: 0 5px;
  margin-left: -10%;
  font-size: 1rem;
`;

const ExternalItem = styled(Menu.Item)`
  align-self: initial !important;
  & a {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Open Sans' Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 1px;
    text-transform: uppercase;
    &:hover {
      color: #4183c4;
    }
  }
`;

const MenuLink = styled(Link)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans' Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-self: initial;
`;

const MenuItem = styled(Menu.Item)`
  text-transform: uppercase;
  align-self: initial !important;
  &: .ui.secondary.pointing.menu .item {
    align-self: initial !important;
  }
`;
