import Joi from "joi";

export const application_schema = Joi.object({
  status: Joi.string()
    .min(6)
    .max(8)
    .required()
    .messages({
        "string.min": `"Status" should have a minimum length of {#limit} characters`,
        "string.max": `"Status" should have a maximum length of {#limit} characters`,
        "any.required": `"Status" is a required field`,
      }),
  reason: Joi.when('status', {
    is: Joi.string().valid('denied'),
    then: Joi.string().min(10).max(250).required().messages({
      "string.min": `"Denial reason" should have a minimum length of {#limit} characters`,
      "string.max": `"Denial reason" should have a maximum length of {#limit} characters`,
      "any.required": `"Denial reason" is a required field`,
    }),
    otherwise: Joi.string().valid(null, '').optional(),
  }),
  orgId: Joi.string()
      .min(5)
      .max(50)
      .required()
      .messages({
        "string.min": `"Organization id" should have a minimum length of {#limit} characters`,
        "string.max": `"Organization id" should have a maximum length of {#limit} characters`,
        "any.required": `"Organization id" is a required field`,
      }),
});
