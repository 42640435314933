import React from 'react';
import './App.css';
import {ErrorBoundary, PageLoader} from '@kwixl/elements';
import {
  FirebaseProvider,
  SessionProvider,
  SessionContext,
} from 'providers';
import {InternalRoutes} from 'routes';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ThemeProvider} from 'styled-components';
import {IntlProvider} from 'react-intl';
import languages from 'intl';

const theme = {
  background: {
    color: '#121212',
  },
  primaryColor: '#03a9f4',
  font: {
    primary: {
      color: '#ffffff',
    },
    secondary: {
      color: 'e2e2e2',
    },
    disabled: {
      color: '#888888',
    },
  },
  footer: {
    background: {
      color: '#222222',
    },
  },
  menu: {
    primary: '#fffff',
    secondary: '#2185D0',
    tertiary: '#1B1C1D',
  },
  light: {
    font: {
      primary: {
        color: '#121212',
      },
    },
    background: {
      color: '#ffffff',
    },
  },
  dark: {
    font: {
      primary: {
        color: '#ffffff',
      },
    },
    background: {
      event: 'rgb(27,27,27)',
      color: '#121212',
    },
  },
};

const App = () => {
  return (
    <ErrorBoundary>
      <FirebaseProvider>
        <SessionProvider>
          <SessionContext.Consumer>
            {({locale, ready}) => (
              <IntlProvider
                locale={locale}
                defaultLocale="en"
                messages={languages[locale]}
              >
                {ready ? (
                  <>
                    <ThemeProvider theme={theme}>
                      <InternalRoutes/>
                    </ThemeProvider>
                    <ToastContainer autoClose={3000} hideProgressBar />
                  </>
                ) : (
                  <PageLoader/>
                )}
              </IntlProvider>
            )}
          </SessionContext.Consumer>
        </SessionProvider>
      </FirebaseProvider>
    </ErrorBoundary>
  );
}

export default App;
