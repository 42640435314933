import React from 'react';
import {useParams} from 'react-router-dom';
import {withAuthenticatedPage} from 'hoc';

export const View = withAuthenticatedPage(({ returnHeader }) => {

    const {id} = useParams();

    return (
        <>
            {returnHeader('Category', '/categories')}
        </>
    )
});