import React from 'react';
import {Header} from 'semantic-ui-react';

export const withBasePage = WrappedComponent => {

  console.log('AT BASE PAGE')
  const header = (size = 1, text) => <Header as={`h${size}`}>{text}</Header>;
  const header1 = (text: string) => header(1, text);
  const header2 = (text: string) => header(2, text);
  const header3 = (text: string) => header(3, text);
  const header4 = (text: string) => header(4, text);
  const header5 = (text: string) => header(5, text);
  const header6 = (text: string) => header(6, text);
  const pageTitle = (text: string) => header2(text);

  console.log('Returning base HOC')
  return class extends React.Component {
    render = () => (
      <WrappedComponent
        header1={header1}
        header2={header2}
        header3={header3}
        header4={header4}
        header5={header5}
        header6={header6}
        pageTitle={pageTitle}
      />
    );
  };
};
