import React from 'react';
import {withAuthenticatedPage} from 'hoc';
import {Link} from 'react-router-dom';
import {ROUTES} from 'routes';

export const Main = withAuthenticatedPage(({ header2 }) => {
    
    return (
       <>
       {header2('Kwixl Admin')}
        <p><Link to={ROUTES.users}>Users</Link></p>
        <p><Link to={ROUTES.categories}>Categories</Link></p>
        <p><Link to={ROUTES.events}>Events</Link></p>
        <p><Link to={ROUTES.applications}>Seller Applications</Link></p>
        {/*<p><Link to={'/demo'}>Demo</Link></p>*/}
       </>
    )

})
