import React, {useContext} from 'react';
import {SessionContext} from 'providers';
import {Grid} from 'semantic-ui-react';
import styled from 'styled-components';
import {SiteTitle} from 'components';
import {useFirebaseContext} from 'hooks';

export const PageFooter = () => {
  const {mobile, demo} = useContext(SessionContext);

  const {userHome, userCanSell, firebaseUser} = useFirebaseContext();

  return (
    <Footer id="page-footer">
      <FooterContainer>
        {mobile ? (
          <div
            style={{
              textAlign: 'center',
              fontSize: '.9rem',
              marginTop: '10px',
              minHeight: '65px',
            }}
          >
            <p>
              &copy; {new Date().getFullYear()} 421, LLC
            </p>
          </div>
        ) : (
          <Grid columns={3} widths="equal">
            <Grid.Row>
              <Grid.Column>
              </Grid.Column>
              <Grid.Column>
              </Grid.Column>
              <Grid.Column>
                <TitleContainer>
                  <SiteTitle />
                </TitleContainer>
                <FooterInfo>
                  <p>
                    &copy; {new Date().getFullYear()} 421, LLC | v{process.env.REACT_APP_VERSION}
                  </p>
                </FooterInfo>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </FooterContainer>
    </Footer>
  );
};

const Footer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 20px !important;
  background-color: black !important;
  padding-bottom: 20px;
  & a:hover {
    text-decoration: underline;
  }
  align-self: stretch;
  flex: 0;
  margin-top: 40px;
`;

const FooterContainer = styled.div`
  width: 55%;
  margin: 0 auto;
`;

const FooterInfo = styled.div`
  font-size: 10px;
  color: #e2e2e2;
`;

const TitleContainer = styled.div`
  font-size: 1.25rem;
`;
