import React, {useRef, useEffect, useReducer} from 'react';
import {Table} from 'semantic-ui-react';
import {withAuthenticatedPage} from 'hoc';
import {EditDemoModal} from './components';
import {DateTime} from 'luxon';
import {
    collection,
    query,
    where,
    onSnapshot
} from 'firebase/firestore';
import {useFirebaseContext} from 'hooks';
import {reducer} from 'lib';

const initialState = {
    sessions: [],
}

export const Main = withAuthenticatedPage(({ returnHeader, header2 }) => {
    
    const {db, updateCollectionArray} = useFirebaseContext();

    const unsubDemo = useRef(() => {});
    const sessionsRef = useRef([]);

    const [{
        sessions,
    }, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        unsubDemo.current = onSnapshot(query(collection(db, 'demo'), where('startAt', '>=', DateTime.utc().toJSDate())), demoListener);
        return unsubDemo.current;
    },[]);

    const demoListener = snap => {
        updateCollectionArray(snap, sessionsRef.current, setSessions);
    }

    const setSessions = snap => {
        sessionsRef.current = snap;
        dispatch({ sessions: sessionsRef.current });
    }

    return (
        <>
            {returnHeader('Kwixl Demo Admin')}
            <EditDemoModal/>
            <Table stackable>
                <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Date
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Customer
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Code
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Notified
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                    {sessions.map(session => (
                        <Table.Row>
                            <Table.Cell>
                                {DateTime.fromJSDate(session.get('startAt').toDate()).toLocaleString(DateTime.DATETIME_FULL)}
                            </Table.Cell>
                            <Table.Cell>
                                {session.get('name')}
                            </Table.Cell>
                            <Table.Cell>
                                {session.get('email')}
                            </Table.Cell>
                            <Table.Cell>
                                {session.id}
                            </Table.Cell>
                            <Table.Cell>
                                {session.get('sentAt') ? DateTime.fromJSDate(session.get('startAt').toDate()).toLocaleString(DateTime.DATETIME_FULL) : null}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    )

})
