import React from 'react';
import {Icon} from 'semantic-ui-react';
import Emoji from 'react-emoji-render';
import isEqual from 'lodash/isEqual';
import {ChatAvatar} from 'components';
import {
  useSessionContext,
} from 'hooks';
import {DocumentSnapshot} from 'firebase/firestore';
import styled from 'styled-components';

const decorators = {
  success: {
    backgroundColor: '#dcffdc',
    color: '#000000',
  },
  default: {
    backgroundColor: 'inherit',
    color: 'inherit',
  },
};

const getDecorator = decorator => decorators[decorator] || decorators.default;

interface Props {
    chat: DocumentSnapshot;
}

export const ChatMessage = ({ chat }: Props) => {

    const {mobile} = useSessionContext();

  try {
    const {
      message = '',
      type,
      decorator,
      handle,
      photoURL,
      displayName = '',
    } = chat.data();

    const handleType = (messageType, handle, displayName) => {
        switch (messageType) {
          case 'admin':
            return `${handle || displayName} (Admin)`;
          case 'system':
            return 'Kwixlbot';
          default:
            return handle || displayName || '';
        }
      };
      
    const isSystem = type === 'system';

    let avatarImage = photoURL;

    console.log('GOT message', message);

    if (message.replace(/\s/gi, '') === '') return null;

    console.log('1');
    
    const showName = true; 

    console.log('2');

    let msg = message;

    console.log('3');

    if (isSystem) {
      avatarImage = `${process.env.REACT_APP_URL}/apple-icon-60x60.png`;
    }

    console.log('4');

    if (decorator === 'success') {
      msg = showName ? `${displayName} (${handle}) ${msg}` : `${handle} ${msg}`;
    }

    console.log('5');

    console.log('here', chat.data())

    console.log('call avatar with', avatarImage, displayName)

    // mobile flag is used for admin mobile presenter view
    return (
      <MessageWrapper>
        <MessageBackground mobile={mobile}>
          <MessageContainer
            messageType={type}
            decorator={decorator}
            mobile={mobile}
            post={true}
          >
            {(chat.get('uid') || chat.get('fbId')) && !isSystem && (
              <AvatarContainer>
                <ChatAvatar
                  image={avatarImage}
                  name={displayName}
                />
              </AvatarContainer>
            )}
            <ContentContainer>
              <Author>
                {handleType(
                  type,
                  showName
                    ? type === 'capture'
                      ? decorator
                      : `${handle ? `${handle} - ` : ''}${displayName || ''}`
                    : type === 'capture'
                    ? decorator
                    : handle,
                  showName ? displayName : ''
                )}
              </Author>
              {type === 'capture' && (
                <FacebookTag>
                  <Icon name="facebook" color="blue" style={{margin: '0'}} />
                </FacebookTag>
              )}
              <AlignedSpan>&nbsp; - &nbsp;</AlignedSpan>
              <StyledEmoji text={msg} />
              {chat.get('claim.id') && (
                <Icon
                  name="check"
                  color={chat.get('claim.status') === 'accepted' ? 'green' : 'grey'}
                  style={{marginLeft: '5px'}}
                />
              )}
            </ContentContainer>
          </MessageContainer>
        </MessageBackground>
      </MessageWrapper>
    );
  } catch (err) {
    console.error(err.message);
    return null;
  }
};

const isSame = (prevProps, nextProps) => {
  return isEqual(prevProps.chat, nextProps.chat);
};

export default React.memo(ChatMessage, isSame);

interface MessageProps {
  mobile?: boolean;
  post?: boolean;
  decorator?: string;
  messageType?: string;
}

const MessageContainer = styled.div<MessageProps>`
  margin: 0 5px 5px 0;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    background-color: transparent;
    padding: 0 1rem;
    color: #ffffff;
  }
  @media (min-width: 769px) {
    margin: 0 0 5px 0;
    color: ${props => {
      if (props.mobile) {
        return '#ffffff';
      } else if (props.post) {
        return 'inherit';
      } else if (props.decorator) {
        return getDecorator(props.decorator).color;
      } else {
        switch (props.messageType) {
          case 'user':
          case 'capture':
            return '#ffffff';
          case 'admin':
          case 'system':
            return '#000000';
          default:
            return 'inherit';
        }
      }
    }};
    background-color: ${props => {
      if (props.mobile) {
        return 'transparent';
      } else if (props.decorator) {
        return getDecorator(props.decorator).backgroundColor;
      } else {
        switch (props.messageType) {
          case 'admin':
            return '#f2f2f2';
          case 'system':
            return 'aliceblue';
          default:
            return 'transparent';
        }
      }
    }};
    padding: 5px 1rem;
  }
`;

const AvatarContainer = styled.div`
  flex: 0 1 42px;
`;

const ContentContainer = styled.div`
  padding-top: 5px;
  flex: 1;
`;

const Actions = styled.div`
  display: block;
  width: 100%;
  font-size: 0.9em;
`;

const Action = styled.span`
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
`;

const MessageWrapper = styled.div`
  width: 100%;
  display: block;
  overflow: hidden;
`;

const AlignedSpan = styled.span`
  vertical-align: sub;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
`;

const Author = styled(AlignedSpan)`
  font-weight: 600;
`;

const FacebookTag = styled(AlignedSpan)`
  background-color: #ffffff;
  margin: 0 2px 0 10px;
  border-radius: 4px;
`;

const StyledEmoji = styled(Emoji)`
  vertical-align: sub;
  vertical-align: -webkit-baseline-middle;
`;

const MessageBackground = styled.div<MessageProps>`
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    padding: 5px;
    margin-left: 1rem;
    margin-right: 0.5rem;
    background: url(/img/backgrounds/semi-transparent.png);
    margin-bottom: 5px;
    color: #ffffff;
    display: inline-block;
  }
  @media (min-width: 769px) {
    background: ${props =>
      props.mobile ? 'url(/img/backgrounds/semi-transparent.png)' : 'none'};
  }
`;
